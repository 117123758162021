import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});
		//console.log($('ul.dropdown'));


	},

	methods: {

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};