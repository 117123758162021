import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';

import {
	fireFormSubmit
}
from 'voUtils/TagEx.js';

import {
	doPost
}
from 'voUtils/RestEx.js';


export default {
	voVueComponent: 'voffice-newsletter-subscription',
	data: function () {
		var fd = VOFFICE.testmode ? {
			email: 'test@v-office.com',
		} : {
			email: '',
		};
		return {
			formData: fd,
			status: 'ready',
			error: undefined
		};

	},
	props: [
		'listId',
		'target',
		'newsletter',
		'newsletterName'
	],
	methods: {
		submit: function () {
			this.status = 'submitting';
			var data = {
				target: this.target || 'mailjet',
				setup: VOFFICE.newsletter && VOFFICE.newsletter[this.newsletter || 'def'],
				form: this.formData
			};

			if (this.listId) {
				data.setup.listId = this.listId;
			}
			if (this.newsletterName) {
				data.setup.newsletterName = this.newsletterName;
			}

			fireFormSubmit(data);
			this.error = undefined;

			doPost(this.ngaction || 'subscribe', data)
				.then((res) => {
					this.status = 'done';
				}).catch(e => {
					this.status = 'failed';
					this.error = e;
				});

		}
	}
};